import React from 'react';

import DesignOptions from './design-options';
import { navigation } from 'config/nav-config';
import CardProfile from 'components/layout/CardProfile';
import Navigation from 'components/core/navigation/Navigation';
import Divider from 'components/core/Divider';

import { motion, useSpring } from 'framer-motion';

import { useTranslation } from 'react-i18next';

function App({ children }) {
  const { t } = useTranslation();
  const profile = t('profile', { returnObjects: true });

  return (
    <div className="jmco-cf037edf-9db0e919">
      <div className="jmco-424058f2-2210eb8b">
        <aside className="jmco-58831ee7-7fb32c96">
          <CardProfile profile={profile} />
        </aside>

        <Divider vertical sticky />

        <main className="jmco-d6238b05-af4f29b1">
          <motion.div className="jmco-1eba496c-74533e03" initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1 }}>
            {children}

            <section className="jmco-1eba496c-74533e03-footer">
              <div className="text-center">
                ©{new Date().getFullYear()} {t('common:all-reserved')} Dravento.
              </div>
            </section>
          </motion.div>
        </main>

        <Divider vertical sticky />

        <aside className="jmco-10518bc8-c1182de3">
          <Navigation list={navigation} />
        </aside>
        <aside className="jmco-87879652-2442b029">
          <Navigation list={navigation} isMobile />
        </aside>
      </div>

      <DesignOptions />
    </div>
  );
}

export default App;
