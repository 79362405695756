import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useScrollControl } from 'providers/ScrollControlProvider';

const ScrollToTop = () => {
  const { scrollEnabled, enableScroll } = useScrollControl();
  const { pathname } = useLocation();

  useEffect(() => {
    if (scrollEnabled) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      enableScroll();
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;
