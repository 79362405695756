import React from 'react';

import PageHeader from 'components/layout/PageHeader';
import App from 'pages/app';

import 'assets/css/components/core/loading.css';

const Loading = () => (
  <App>
    <div className="jmco-1eba496c-74533e03">
      <PageHeader title="Loading Page" icon="loading-icon" />

      <div className="jmco-1eba496c-74533e03-section">
        <div className="jmco-16c6e233-66ea8011">
          <img src="logo512.jpg" alt="Dravinto" width="100" />
          <div>
            <i className="loading-icon"></i>
            <p>Loading...</p>
          </div>
        </div>
      </div>
    </div>
  </App>
);

export default Loading;
