import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import enCommon from 'assets/i18n/en/common.json';
import enAbout from 'assets/i18n/en/about.json';
import enBlog from 'assets/i18n/en/blog.json';
import enContact from 'assets/i18n/en/contact.json';
import enError from 'assets/i18n/en/error.json';
import enResume from 'assets/i18n/en/resume.json';
import enShop from 'assets/i18n/en/shop.json';
import enWork from 'assets/i18n/en/work.json';

import arCommon from 'assets/i18n/ar/common.json';
import arAbout from 'assets/i18n/ar/about.json';
import arBlog from 'assets/i18n/ar/blog.json';
import arContact from 'assets/i18n/ar/contact.json';
import arError from 'assets/i18n/ar/error.json';
import arResume from 'assets/i18n/ar/resume.json';
import arShop from 'assets/i18n/ar/shop.json';
import arWork from 'assets/i18n/ar/work.json';

const resources = {
  en: {
    common: enCommon,
    about: enAbout,
    blog: enBlog,
    contact: enContact,
    error: enError,
    resume: enResume,
    shop: enShop,
    work: enWork,
  },
  ar: {
    common: arCommon,
    about: arAbout,
    blog: arBlog,
    contact: arContact,
    error: arError,
    resume: arResume,
    shop: arShop,
    work: arWork,
  },
};

i18n
  // .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    whitelist: ['en', 'ar'],
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ['common', 'about', 'blog', 'contact', 'error', 'resume', 'shop', 'work'],
    defaultNS: 'common',
  });

export default i18n;
