import React, { memo, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import 'assets/css/components/core/navigation/navigation-item-hover-popover.css';

function useOutsideAlerter(ref, ignoredTags, onOutsideClick) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && !ignoredTags.some(tag => event.target.closest(tag))) {
        onOutsideClick();
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, ignoredTags, onOutsideClick]);
}

const NavigationItemHoverPopover = ({
  id,
  to,
  content,
  iconClass,
  text,
  active,
  isOpen,
  handlePopoverOpen,
  small,
  isSidebarOpen,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const timeoutRef = useRef(null);
  const popoverRef = useRef(null);

  useEffect(() => {
    document.getElementsByClassName('jmco-10518bc8-c1182de3')[0].style.zIndex = isHovered ? 999 : '';
  }, [isHovered]);

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    handlePopoverOpen(id);
    timeoutRef.current = setTimeout(() => {
      setIsHovered(true);
    }, 50);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsHovered(false);
    }, 3000);
  };

  const handlePopoverMouseEnter = () => {
    clearTimeout(timeoutRef.current);
  };

  const handlePopoverMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsHovered(false);
    }, 3000);
  };

  const navItemClass = classNames({
    'jmco-91d16714-b173e0fd-item-active': active,
  });

  useOutsideAlerter(popoverRef, ['li'], () => setIsHovered(false));

  if (small) {
    return (
      <li className={`jmco-da3ff58b-1f26142c ${isSidebarOpen ? 'jmco-da3ff58b-1f26142c-open' : ''}`} ref={popoverRef}>
        <NavLink
          to={to ? to : '#'}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className={navItemClass}
          aria-expanded={isHovered}
        >
          <i className={`${iconClass} jmco-da3ff58b-1f26142c-icon jmco-3b29353b-acd36d3a`}></i>
        </NavLink>
        {isOpen != null && isOpen && isHovered && (
          <div
            className="jmco-91d16714-b173e0fd-item-popover-content"
            onMouseEnter={handlePopoverMouseEnter}
            onMouseLeave={handlePopoverMouseLeave}
          >
            {content}
          </div>
        )}
      </li>
    );
  }

  return (
    <li className="jmco-91d16714-b173e0fd-item-popover" ref={popoverRef}>
      <NavLink
        to={to ? to : '#'}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={navItemClass}
        aria-expanded={isHovered}
      >
        <i className={`${iconClass}`}></i>
        {text && <span>{text}</span>}
      </NavLink>
      {isOpen != null && isOpen && isHovered && (
        <div
          className="jmco-91d16714-b173e0fd-item-popover-content"
          onMouseEnter={handlePopoverMouseEnter}
          onMouseLeave={handlePopoverMouseLeave}
        >
          {content}
        </div>
      )}
    </li>
  );
};

NavigationItemHoverPopover.propTypes = {
  content: PropTypes.node.isRequired,
  iconClass: PropTypes.string.isRequired,
  text: PropTypes.string,
  active: PropTypes.bool,
};

export default NavigationItemHoverPopover;
