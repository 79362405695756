import React from 'react';

import AttributeView from 'components/section/AttributeView';
import { useDesignOptions } from 'providers/DesignOptionsProvider';
import TypeWriter from 'components/core/TypeWriter';
import Divider from 'components/core/Divider';
import SocialLink from 'components/section/SocialLink';

import 'assets/css/components/layout/card-profile.css';
import allImages from 'assets/images';
import { useTranslation } from 'react-i18next';

function CardProfile({ profile }) {
  const { profileImage } = useDesignOptions();
  const { t } = useTranslation('common');

  return (
    <div className="jmco-85d67678-3e89519c">
      <div className="jmco-85d67678-3e89519c-content">
        <img src={allImages[profileImage]} alt={`Profile of ${profile.fullName}`} />
        <div className="jmco-85d67678-3e89519c-name-wrapper">
          <h1 className="jmco-85d67678-3e89519c-full-name">{profile.fullName}</h1>
          <div className="jmco-85d67678-3e89519c-specialist">
            <TypeWriter prefix={profile.prefixSpecialists} texts={profile.specialists} delayBetweenTexts={4000} />
          </div>
        </div>

        <div className="jmco-85d67678-3e89519c-detail">
          <AttributeView label={t('hello')} value={profile.bio} wFull />
          <AttributeView label={t('mail')} value={profile.email} iconClass={'fa-regular fa-envelope'} />
          <AttributeView label={t('location')} value={profile.location} iconClass={'fa-solid fa-location-dot'} />
          <AttributeView label={t('availability')} value={profile.availability} iconClass={'fa-regular fa-handshake'} />
        </div>

        <Divider />

        <div className="jmco-85d67678-3e89519c-follow-me">
          <SocialLink
            label="Facebook"
            iconClass="fa-brands fa-facebook"
            colorClass="text-blue-600 dark:text-blue-400"
            href={t('profile.links.facebook')}
          />
          <SocialLink
            label="Instagram"
            iconClass="fa-brands fa-instagram"
            colorClass="text-purple-500 dark:text-purple-300"
            href={t('profile.links.instagram')}
          />
          <SocialLink
            label="Linkedin"
            iconClass="fa-brands fa-linkedin"
            colorClass="text-blue-700 dark:text-blue-500"
            href={t('profile.links.linkedin')}
          />
          <SocialLink
            label="X"
            iconClass="fa-brands fa-x-twitter"
            colorClass="text-blue-400 dark:text-blue-200"
            href={t('profile.links.twitter')}
          />
          <SocialLink
            label="Youtube"
            iconClass="fa-brands fa-youtube"
            colorClass="text-red-500 dark:text-red-300"
            href={t('profile.links.youtube')}
          />
          <SocialLink
            label="Github"
            iconClass="fa-brands fa-github"
            colorClass="text-gray-700 dark:text-gray-500"
            href={t('profile.links.github')}
          />
        </div>

        <div className="jmco-85d67678-3e89519c-quick-links">
          <button>
            <i className="fa-solid fa-download"></i>
            <span>{t('download_cv')}</span>
          </button>
          <button>
            <i className="fa-solid fa-envelope-open-text"></i>
            <span>{t('contact_me')}</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default React.memo(CardProfile);
