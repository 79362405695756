import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import NavigationItemHoverPopover from 'components/core/navigation/NavigationItemHoverPopover';

import { useTranslation } from 'react-i18next';
import { useDesignOptions } from 'providers/DesignOptionsProvider';

const NavigationItem = ({
  id,
  to,
  iconClass,
  text,
  popoverContent,
  indicator,
  isOpen,
  handlePopoverOpen,
  handlePopoverClose,
  small,
  isSidebarOpen,
  textClass,
  textClassAr,
}) => {
  const { t } = useTranslation('common');
  const { direction } = useDesignOptions();
  const isLTR = direction === 'ltr';
  const location = useLocation();
  var isActive = location.pathname.includes(to);

  if (location.pathname === '/' && to === '/about') {
    isActive = true;
  }

  if (popoverContent) {
    return (
      <NavigationItemHoverPopover
        id={id}
        handlePopoverOpen={handlePopoverOpen}
        isOpen={isOpen}
        to={to}
        content={popoverContent}
        iconClass={iconClass}
        active={isActive}
        text={text}
        small={small}
        isSidebarOpen={isSidebarOpen}
      />
    );
  }

  if (small) {
    return (
      <NavLink key={id} to={to} className={`jmco-da3ff58b-1f26142c ${isSidebarOpen ? 'jmco-da3ff58b-1f26142c-open' : ''}`}>
        <i className={`${iconClass} jmco-da3ff58b-1f26142c-icon`}></i>
      </NavLink>
    );
  }

  return (
    <li className="jmco-91d16714-b173e0fd-item group">
      <NavLink to={to} className={`${isActive ? 'jmco-91d16714-b173e0fd-item-active' : ''}`}>
        <i className={`${iconClass}`}></i>
        <span className={`${textClassAr && !isLTR && textClass ? textClass : ''}`}>{t(text)}</span>
        {indicator && <div className="jmco-91d16714-b173e0fd-item-indicator">{indicator}</div>}
      </NavLink>
    </li>
  );
};

export default React.memo(NavigationItem);
