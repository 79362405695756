import React, { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTheme } from 'providers/ThemeProvider';

import PropTypes from 'prop-types';
import NavigationList from './NavigationList';
import Divider from 'components/core/Divider';

import 'assets/css/components/core/navigation/navigation.css';
import { useTranslation } from 'react-i18next';

function Navigation({ list, isMobile = false }) {
  const { t } = useTranslation('common');
  const location = useLocation();
  const { theme, setTheme } = useTheme();

  const updatedList = useMemo(() => {
    const iconClass =
      theme === 'dark' ? 'fa-regular fa-moon' : theme === 'system' ? 'fa-solid fa-desktop' : 'fa-regular fa-sun';
    const updatedQuickNavItem = { ...list.quickNav[0], iconClass };

    return {
      ...list,
      quickNav: [updatedQuickNavItem, ...list.quickNav.slice(1)],
    };
  }, [theme, list.quickNav]);

  return (
    <nav>
      {isMobile ? (
        <div className="jmco-91d16714-b173e0fd-mobile">
          <div className="jmco-91d16714-b173e0fd-mobile-container">
            {updatedList['pagesNav'].map(item => (
              <NavLink
                key={item.id}
                to={item.to}
                className={`${location.pathname === item.to ? 'jmco-91d16714-b173e0fd-item-active' : ''} jmco-91d16714-b173e0fd-mobile-link group`}
              >
                <i className={`${item.iconClass}`} aria-hidden="true"></i>
                <span>{t(item.text)}</span>
              </NavLink>
            ))}
          </div>
        </div>
      ) : (
        <div className="jmco-91d16714-b173e0fd">
          {Object.keys(updatedList).map((key, index) => (
            <React.Fragment key={index}>
              <NavigationList items={updatedList[key]} />
              {index < Object.keys(updatedList).length - 1 && <Divider alreadyCentered />}
            </React.Fragment>
          ))}
        </div>
      )}
    </nav>
  );
}

Navigation.propTypes = {
  list: PropTypes.shape({
    quickNav: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        to: PropTypes.string.isRequired,
        iconClass: PropTypes.string.isRequired,
        popoverContent: PropTypes.element,
      })
    ).isRequired,
    pagesNav: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        to: PropTypes.string.isRequired,
        iconClass: PropTypes.string.isRequired,
        text: PropTypes.string,
        indicator: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
  isMobile: PropTypes.bool,
};

export default React.memo(Navigation);
