import React from 'react';
import PropTypes from 'prop-types';

import 'assets/css/components/section/attribute-view.css';

function AttributeView({ value = '', label = '', iconClass = '', wFull = false }) {
  return (
    <div className={`jmco-ebc2e921-0cc79bd2 ${wFull ? 'jmco-ebc2e921-0cc79bd2-full' : ''}`}>
      <div className="jmco-ebc2e921-0cc79bd2-container">
        {iconClass && (
          <div className="jmco-ebc2e921-0cc79bd2-icon">
            <i className={`${iconClass}`}></i>
          </div>
        )}
        <div>
          <span className="jmco-ebc2e921-0cc79bd2-label">{label}</span>
          <h3 className="jmco-ebc2e921-0cc79bd2-value">{value}</h3>
        </div>
      </div>
    </div>
  );
}

AttributeView.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  label: PropTypes.string,
  iconClass: PropTypes.string,
  wFull: PropTypes.bool,
};

export default React.memo(AttributeView);
