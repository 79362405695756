import React, { useState, useEffect } from 'react';
import SvgSomethingWentWrong from 'assets/svg/SvgSomethingWentWrong';
import PageHeader from 'components/layout/PageHeader';
import App from 'pages/app';

import 'assets/css/components/core/error-boundary.css';
import Button from './Button';

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  const getDerivedStateFromError = () => {
    setHasError(true);
  };

  useEffect(() => {
    const resetError = () => setHasError(false);
    window.addEventListener('error', getDerivedStateFromError);
    window.addEventListener('unhandledrejection', getDerivedStateFromError);

    return () => {
      window.removeEventListener('error', getDerivedStateFromError);
      window.removeEventListener('unhandledrejection', getDerivedStateFromError);
    };
  }, []);

  if (hasError) {
    return (
      <App>
        <div className="jmco-1eba496c-74533e03">
          <PageHeader title={''} icon={''} />

          <div className="jmco-1eba496c-74533e03-section">
            <div className="jmco-7e7a73c1-c7a739ac">
              <SvgSomethingWentWrong width="250" />
              <div>
                <p>Something went wrong!!</p>
              </div>
              <br />
              <br />
              <Button
                label="Go back to home"
                onClick={() => setHasError(false)}
                navigateTo={'/'}
                button
                forward={true}
              />
            </div>
          </div>
        </div>
      </App>
    );
  }

  return children;
};

export default ErrorBoundary;
