import React, { useState, useEffect } from 'react';

import 'assets/css/components/core/type-writer.css';

const TypeWriter = ({ texts = [], speed = 100, delayBetweenTexts = 1000, prefix, onComplete, newLine }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (texts.length === 0) return;

    const currentText = texts[currentTextIndex];
    const updateText = () => {
      const nextCharIndex = isDeleting ? charIndex - 1 : charIndex + 1;
      setCharIndex(nextCharIndex);

      const isTypingComplete = !isDeleting && nextCharIndex === currentText.length;
      const isDeletingComplete = isDeleting && nextCharIndex === 0;

      if (isTypingComplete) {
        setTimeout(() => setIsDeleting(true), delayBetweenTexts);
      } else if (isDeletingComplete) {
        setIsDeleting(false);
        setCurrentTextIndex((currentTextIndex + 1) % texts.length);
        if ((currentTextIndex + 1) % texts.length === 0 && onComplete) {
          onComplete(); // Call the onComplete callback when all texts have been cycled through
        }
      }
    };

    const typingDelay = isDeleting ? speed / 2 : speed;
    const timeoutId = setTimeout(updateText, typingDelay);

    return () => clearTimeout(timeoutId);
  }, [texts, currentTextIndex, charIndex, isDeleting, speed, delayBetweenTexts, onComplete]);

  return (
    <div className="jmco-f53783a9-1aac45f4" role="presentation" aria-live="polite">
      {prefix && <span className="jmco-f53783a9-1aac45f4-prefix">{prefix}</span>}
      <span className="jmco-f53783a9-1aac45f4-label" aria-label={texts[currentTextIndex]}>
        {texts[currentTextIndex].substring(0, charIndex)}
      </span>
      <span className="jmco-f53783a9-1aac45f4-cursor"></span>
    </div>
  );
};

export default React.memo(TypeWriter);
