import React from 'react';

import 'assets/css/components/core/tooltip.css';

export default function Tooltip({ label }) {
  return (
    <span className="jmco-af526f90-a980f3e9-wrapper group ">
      <span className="jmco-af526f90-a980f3e9">
        <span className="jmco-af526f90-a980f3e9-arrow"></span>
        <span className="jmco-af526f90-a980f3e9-label">{label}</span>
      </span>
    </span>
  );
}
