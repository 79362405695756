import React, { useContext, useState } from 'react';

import i18n from 'i18next';

const DesignOptionsContext = React.createContext();

export const useDesignOptions = () => useContext(DesignOptionsContext);

export const DesignOptionsProvider = ({ children }) => {
  const [selectedProfileImage, setSelectedProfileImage] = useState();
  const [selectedTheme, setSelectedTheme] = useState();
  const [selectedFont, setSelectedFont] = useState();
  const [selectedLine, setSelectedLine] = useState();
  const [selectedScrollbar, setSelectedScrollbar] = useState();
  const [direction, setDirection] = useState('ltr');
  const [roundAll, setRoundAll] = useState(0.3);
  const [shadowAll, setShadowAll] = useState(false);
  const [profileImage, setProfileImage] = useState('profile/boy-profile-1');
  const [selectedGender, setSelectedGender] = useState('male');

  const contextValue = {
    selectedProfileImage,
    setSelectedProfileImage,
    selectedTheme,
    setSelectedTheme,
    selectedFont,
    setSelectedFont,
    selectedLine,
    setSelectedLine,
    selectedScrollbar,
    setSelectedScrollbar,
    roundAll,
    setRoundAll,
    shadowAll,
    setShadowAll,
    selectedGender,
    setSelectedGender,
    direction,
    setDirection,
    profileImage,
    setProfileImage,
  };

  const toggleDirection = () => {
    setDirection(prevDirection => (prevDirection === 'ltr' ? 'rtl' : 'ltr'));
  };

  React.useEffect(() => {
    document.body.setAttribute('dir', direction);
    
    i18n.changeLanguage(direction === 'ltr' ? 'en' : 'ar');
  }, [direction]);

  return <DesignOptionsContext.Provider value={contextValue}>{children}</DesignOptionsContext.Provider>;
};
