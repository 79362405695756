import React, { useState } from 'react';
import PropTypes from 'prop-types';

import 'assets/css/components/core/slider.css';

const Slider = ({ initValue = 1.0, onChangeValue = () => {} }) => {
  const [value, setValue] = useState(initValue);

  const handleChange = e => {
    const newValue = e.target.value;
    setValue(newValue);
    onChangeValue(newValue);
  };

  return (
    <div className="jmco-c7be610a-5f7db376">
      <input type="range" min="0.0" max="2.0" step="0.1" value={value} onChange={handleChange} />
      <span>{value}</span>
    </div>
  );
};

Slider.propTypes = {
  initValue: PropTypes.number,
  onChangeValue: PropTypes.func,
};

export default Slider;
