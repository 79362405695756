import React from 'react';

import { useTheme } from 'providers/ThemeProvider';

import 'assets/css/components/section/theme-selection-quick-view.css';
import { useTranslation } from 'react-i18next';

export default function ThemeSelectionQuickView({ label, wFull, goTo, className }) {
  const { t, i18n } = useTranslation();
  const { theme, setTheme } = useTheme();

  const activeStyle = currentTheme =>
    theme === currentTheme ? 'bg-[rgb(var(--primary-color-400))]' : 'hover:bg-[rgb(var(--primary-color-200))]';

  return (
    <div className={`${wFull ? 'w-full' : ''} jmco-e465baf9-151e0d70 ${className}`}>
      <div>
        <button onClick={() => setTheme('light')} className={`${activeStyle('light')}`}>
          <i className="fa-regular fa-sun"></i> {t('light')}
        </button>
        <button onClick={() => setTheme('dark')} className={`${activeStyle('dark')}`}>
          <i className="fa-regular fa-moon"></i> {t('dark')}
        </button>
        <button onClick={() => setTheme('system')} className={`${activeStyle('system')}`}>
          <i className="fa-solid fa-desktop"></i> {t('system')}
        </button>
      </div>
    </div>
  );
}
