import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'assets/css/components/core/button.css';

export default function Button({
  label,
  icon,
  wFull,
  navigateTo,
  button,
  forward = false, // Default to false if not provided
  className,
  onClick,
  color = 'jmco-0a1afa38-1df69626-default-color',
}) {
  const navigate = useNavigate();
  const isIconString = typeof icon === 'string';

  const handleClick = e => {
    e.stopPropagation(); // Stop event propagation
    if (onClick) {
      onClick();
    }
    if (navigateTo) {
      navigate(navigateTo, {
        state: forward ? { forward: true } : {}, // Conditionally pass the forward flag
        replace: true, // This option forces reload by replacing the current entry
      });
    }
  };

  if (button) {
    return (
      <button onClick={handleClick} className={`jmco-0a1afa38-1df69626 ${wFull ? 'w-full' : ''} ${color} ${className || ''}`}>
        {icon && isIconString ? <i className={icon}></i> : icon} {label}
      </button>
    );
  }

  return (
    <input type="submit" value={label} className={`jmco-0a1afa38-1df69626 ${wFull ? 'w-full' : ''} ${color} ${className || ''}`} />
  );
}
