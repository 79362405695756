import React, { useState } from 'react';
import NavigationItem from './NavigationItem';

function NavigationList({ items }) {
  const [popoverId, setPopoverId] = useState(null);

  const handlePopoverOpen = popoverId => {
    setPopoverId(popoverId);
  };

  const handlePopoverClose = () => {
    setPopoverId(null);
  };

  return (
    <ul>
      {items.map(item => (
        <NavigationItem
          key={item.to}
          {...item}
          isOpen={popoverId == item.id}
          handlePopoverOpen={handlePopoverOpen}
          handlePopoverClose={handlePopoverClose}
        />
      ))}
    </ul>
  );
}
export default React.memo(NavigationList);
