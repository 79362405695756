import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { navigation } from 'config/nav-config';
import { useDesignOptions } from 'providers/DesignOptionsProvider';
import NavigationItem from 'components/core/navigation/NavigationItem';
import SocialLink from 'components/section/SocialLink';
import TypeWriter from 'components/core/TypeWriter';

import { useTranslation } from 'react-i18next';
import Divider from 'components/core/Divider';
import allImages from 'assets/images';

function PageHeader({ title, icon }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { profileImage, setProfileImage } = useDesignOptions();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [popoverId, setPopoverId] = useState(null);

  const profile = t('profile', { returnObjects: true });

  const handlePopoverOpen = popoverId => {
    setPopoverId(popoverId);
  };

  const handlePopoverClose = () => {
    setPopoverId(null);
  };

  const toggleMenu = () => {
    setIsSidebarOpen(prevState => !prevState);
  };

  const navigateToContactUs = () => {
    navigate(`/contact`);
  };

  return (
    <>
      <div className="jmco-1eba496c-74533e03-sm-nav">
        <div className="jmco-990ea32f-3b297552"></div>
        <div className="">
          <div className="jmco-7e9c89b5-a29e553b-container jmco-shadow-default-fixed" onClick={toggleMenu}>
            <div className={`jmco-7e9c89b5-a29e553b ${isSidebarOpen ? 'jmco-7e9c89b5-a29e553b-open' : ''}`}>
              <div className="jmco-7e9c89b5-a29e553b-line"></div>
              <div className="jmco-7e9c89b5-a29e553b-line"></div>
              <div className="jmco-7e9c89b5-a29e553b-line"></div>
            </div>
          </div>
          <div className={`nav ${isSidebarOpen ? 'jmco-e601f305-4ddf0418 jmco-e601f305-4ddf0418-open' : ''}`}>
            {navigation['quickNav'].map(item => (
              <NavigationItem
                {...item}
                key={item.to}
                isOpen={popoverId == item.id}
                handlePopoverOpen={handlePopoverOpen}
                handlePopoverClose={handlePopoverClose}
                small
              />
            ))}
          </div>
        </div>
      </div>
      <div className="jmco-1eba496c-74533e03-sm-detail">
        <div className="jmco-1eba496c-74533e03-sm-left jmco-85d67678-3e89519c-follow-me">
          <SocialLink
            enableTooltip={false}
            label="Facebook"
            iconClass="fa-brands fa-facebook"
            colorClass="text-blue-600"
            href={t('profile.links.facebook')}
          />
          <SocialLink
            enableTooltip={false}
            label="Instagram"
            iconClass="fa-brands fa-instagram"
            colorClass="text-purple-500"
            href={t('profile.links.instagram')}
          />
          <SocialLink
            enableTooltip={false}
            label="Linkedin"
            iconClass="fa-brands fa-linkedin"
            colorClass="text-blue-700"
            href={t('profile.links.linkedin')}
          />
          <SocialLink
            enableTooltip={false}
            label="Twitter"
            iconClass="fa-brands fa-x-twitter"
            colorClass="text-blue-400"
            href={t('profile.links.twitter')}
          />
          <SocialLink
            enableTooltip={false}
            label="Youtube"
            iconClass="fa-brands fa-youtube"
            colorClass="text-red-500"
            href={t('profile.links.youtube')}
          />
          <SocialLink
            enableTooltip={false}
            label="Github"
            iconClass="fa-brands fa-github"
            colorClass="text-gray-700"
            href={t('profile.links.github')}
          />
        </div>
        <div className="jmco-85d67678-3e89519c-content">
          <img src={allImages[profileImage]} alt={`Profile of ${profile.fullName}`} />
          <div className="jmco-85d67678-3e89519c-name-wrapper">
            <h1 className="jmco-85d67678-3e89519c-full-name">{profile.fullName}</h1>
          </div>
        </div>
        <div className="jmco-1eba496c-74533e03-sm-right">
          <a href="#" className="group">
            <i className="fa-solid fa-download"></i>
          </a>
          <a href="#" onClick={navigateToContactUs} className="group">
            <i className="fa-solid fa-envelope-open-text"></i>
          </a>
        </div>
      </div>
      <div className="jmco-85d67678-3e89519c-specialist">
        <TypeWriter prefix={'I am a '} texts={profile.specialists} delayBetweenTexts={4000} />
      </div>
      {title && (
        <div className="jmco-1eba496c-74533e03-header">
          <div className="jmco-1eba496c-74533e03-title">
            <h1>
              <i className={icon}></i>
              <span>{title}</span>
            </h1>
          </div>

          {/* <div className="jmco-1eba496c-74533e03-separator"></div> */}

          <Divider className="w-full m-0 mt-2" />
        </div>
      )}
    </>
  );
}

export default React.memo(PageHeader);
