import React from 'react';
import { useNavigate } from 'react-router-dom';
import Divider from 'components/core/Divider';

import 'assets/css/components/section/shopping-cart-quick-view.css';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import allImages from 'assets/images';

export default function ShoppingCartQuickView() {
  const { t } = useTranslation('shop');
  const product = t('product', { returnObjects: true });

  const navigate = useNavigate();

  const openCart = () => {
    navigate(`/cart`);
  };
  const openCheckout = () => {
    navigate(`/cart/checkout`);
  };
  return (
    <div className="jmco-840dc503-333b53a9">
      {product.selectedProducts.map(item => (
        <CartItemLeft key={item.id} item={item} />
      ))}
      <div className="jmco-840dc503-333b53a9-container">
        <Divider className={'w-full'} color={'border-green-700'} />
        <div className="jmco-840dc503-333b53a9-container-total">
          <span>Subtotal:</span>
          <span>{`$${product.selectedProducts.reduce((total, item) => total + item.price, 0).toFixed(2)}`}</span>
        </div>
        <button onClick={openCart}>
          <i className="fa-solid fa-cart-arrow-down"></i>
          <span>VIEW CART</span>
        </button>
        <button onClick={openCheckout}>
          <i className="fa-solid fa-file-invoice-dollar"></i>
          <span>Checkout</span>
        </button>
      </div>
    </div>
  );
}

const CartItemLeft = ({ item }) => (
  <div className="jmco-840dc503-333b53a9-item-left">
    <img src={allImages[item.imageUrl]} alt={item.name} />
    <div>
      <div className="jmco-840dc503-333b53a9-item-left-name">{item.name}</div>
      <div className="jmco-840dc503-333b53a9-item-left-price">{`1 x $${item.price.toFixed(2)}`}</div>
    </div>
  </div>
);
