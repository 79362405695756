import React, { useState } from 'react';
import { theme } from 'config/theme-config';
import SvgFemale from 'assets/svg/SvgFemale';
import SvgMale from 'assets/svg/SvgMale';
import Slider from 'components/core/Slider';
import { useDesignOptions } from 'providers/DesignOptionsProvider';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { menuSlide } from 'utils/animations';

import styles from './design-options.module.scss';
import Curve from './curve';

function DesignOptions() {
  const {
    selectedTheme,
    setSelectedTheme,
    selectedFont,
    setSelectedFont,
    selectedLine,
    setSelectedLine,
    selectedScrollbar,
    setSelectedScrollbar,
    roundAll,
    setRoundAll,
    shadowAll,
    setShadowAll,
    selectedGender,
    setSelectedGender,
    direction,
    setDirection,
    profileImage,
    setProfileImage,
  } = useDesignOptions();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleLayoutPosition = position => {
    setDirection(position);
  };

  const handleGenderChange = gender => {
    setSelectedGender(gender);
    setProfileImage(gender === 'male' ? 'profile/boy-profile-1' : 'profile/girl-profile-1');
  };

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const reset = () => {
    setSelectedTheme(undefined);
    setSelectedFont(undefined);
    setShadowAll(false);
    setRoundAll(0.3);

    const existingThemeStyle = document.getElementById('color-theme-style');
    if (existingThemeStyle) {
      existingThemeStyle.remove();
    }
    const existingFontStyle = document.getElementById('font-theme-style');
    if (existingFontStyle) {
      existingFontStyle.remove();
    }
    document.documentElement.style.setProperty('--round-all', '');
    document.documentElement.style.setProperty('--shadow-all', '');
    document.documentElement.style.setProperty('--scrollbar-color', '');
    document.documentElement.style.setProperty('--divider-border-color', '');
  };

  const changeColorTheme = theme => {
    setSelectedTheme(theme);

    const existingThemeStyle = document.getElementById('color-theme-style');
    if (existingThemeStyle) {
      existingThemeStyle.remove();
    }

    const themeStyle = document.createElement('link');
    themeStyle.rel = 'stylesheet';
    themeStyle.type = 'text/css';
    themeStyle.href = `${process.env.PUBLIC_URL}/${theme.path}`;
    themeStyle.id = 'color-theme-style';
    document.head.appendChild(themeStyle);
  };

  const changeFont = font => {
    setSelectedFont(font);

    const existingFontStyle = document.getElementById('font-theme-style');
    if (existingFontStyle) {
      existingFontStyle.remove();
    }

    if (font.path) {
      const fontStyle = document.createElement('link');
      fontStyle.rel = 'stylesheet';
      fontStyle.type = 'text/css';
      fontStyle.href = `${process.env.PUBLIC_URL}/${font.path}`;
      fontStyle.id = 'font-theme-style';
      document.head.appendChild(fontStyle);
    }
  };

  const changeLineTheme = line => {
    setSelectedLine(line);
    document.documentElement.style.setProperty('--divider-border-color', line.color);
  };

  const changeScrollbarTheme = scrollbar => {
    setSelectedScrollbar(scrollbar);
    document.documentElement.style.setProperty('--scrollbar-color', scrollbar.color);
  };

  const changeRoundAll = newValue => {
    setRoundAll(newValue);
    document.documentElement.style.setProperty('--round-all', `${newValue}rem`);
  };

  const changeShadowAll = e => {
    setShadowAll(e.target.checked);
    document.documentElement.style.setProperty('--shadow-all', e.target.checked ? 'var(--shadow-default-fixed)' : '');
  };

  return (
    <div dir="ltr" className="fixed bottom-6 ltr:right-2 rtl:left-2 z-[999]">
      <button
        className="bg-gray-100 text-white px-3 py-2 rounded-full shadow-md hover:bg-white focus:outline-none"
        onClick={openDrawer}
      >
        <i className="fa-solid fa-gear jmco-flex-center-v text-2xl animate-spin text-black"></i>
      </button>

      <AnimatePresence mode="wait">
        {isDrawerOpen && (
          <div>
            <motion.div variants={menuSlide} initial="initial" animate="enter" exit="exit" className={styles.menu}>
              <div className={styles.body} tabIndex="-1" aria-labelledby="drawer-right-label">
                <h5 className="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400">
                  <i className="fa-solid fa-circle-info jmco-flex-center-v ltr:pr-1 rtl:pr-1"></i>
                  Settings
                </h5>
                <button
                  type="button"
                  onClick={closeDrawer}
                  className="z-99 text-black bg-white hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 right-6 inline-flex items-center justify-center dark:hover:bg-white dark:hover:text-white"
                >
                  <i className="fa-solid fa-xmark jmco-flex-center-v"></i>
                  <span className="sr-only">Close menu</span>
                </button>
                <div className="space-y-3">
                  <section>
                    <p className="text-sm text-[var(--primary-color-900)] dark:text-[var(--primary-color-600)]">Text Direction:</p>
                    <div className="flex items-center gap-2">
                      <label
                        className={`flex items-center justify-start p-1 px-3 border border-white rounded dark:border-white hover:bg-white ${direction === 'ltr' && 'bg-white'} cursor-pointer`}
                      >
                        <input
                          type="radio"
                          className="w-4 h-4 cursor-pointer text-blue-600 bg-white border-white focus:ring-white dark:focus:ring-white dark:ring-offset-white dark:bg-white dark:border-white"
                          value="ltr"
                          checked={direction === 'ltr'}
                          onChange={() => handleLayoutPosition('ltr')}
                        />
                        <span className="ml-2">LTR</span>
                      </label>
                      <label
                        className={`flex items-center justify-start p-1 px-3 border border-white rounded dark:border-white hover:bg-white ${direction !== 'ltr' && 'bg-white'} cursor-pointer`}
                      >
                        <input
                          type="radio"
                          className="w-4 h-4 cursor-pointer text-pink-600 bg-white border-white focus:ring-white dark:focus:ring-white dark:ring-offset-white dark:bg-white dark:border-white"
                          value="rtl"
                          checked={direction === 'rtl'}
                          onChange={() => handleLayoutPosition('rtl')}
                        />
                        <span className="ml-2">RTL</span>
                      </label>
                    </div>
                  </section>
                  <section>
                    <p className="text-sm text-gray-500 dark:text-white">Image type:</p>
                    <div className="flex items-center gap-2">
                      <label
                        className={`flex items-center justify-start p-1 px-3 border border-white rounded dark:border-white hover:bg-white ${selectedGender === 'male' && 'bg-blue-500'} cursor-pointer`}
                      >
                        <input
                          type="radio"
                          className="w-4 h-4 cursor-pointer text-blue-600 bg-white border-gray-300 focus:ring-white dark:focus:ring-white dark:ring-offset-white dark:bg-white dark:border-white"
                          value="male"
                          checked={selectedGender === 'male'}
                          onChange={() => handleGenderChange('male')}
                        />
                        <span className="ml-2">
                          <SvgMale />
                        </span>
                      </label>
                      <label
                        className={`flex items-center justify-start p-1 px-3 border border-white rounded dark:border-white hover:bg-white ${selectedGender !== 'male' && 'bg-pink-500'} cursor-pointer`}
                      >
                        <input
                          type="radio"
                          className="w-4 h-4 cursor-pointer text-pink-600 bg-white border-white focus:ring-white dark:focus:ring-white dark:ring-offset-white dark:bg-white dark:border-white"
                          value="female"
                          checked={selectedGender === 'female'}
                          onChange={() => handleGenderChange('female')}
                        />
                        <span className="ml-2">
                          <SvgFemale />
                        </span>
                      </label>
                    </div>
                  </section>
                  <section>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Theme pair colors:</p>
                    <div className="flex flex-wrap">
                      {theme.colors.map((theme, index) => {
                        const isSelected = selectedTheme && selectedTheme.path === theme.path;
                        return (
                          <div
                            key={index}
                            className={`w-1/3 p-1 jmco-flex-center-v hover:border ${isSelected ? 'border border-white' : 'border-white'} hover:border-white rounded-lg focus:outline-none`}
                            style={{direction: "rtl"}}
                          >
                            <button
                              title={theme.desc}
                              className="w-full p-5 rounded-lg relative"
                              onClick={() => changeColorTheme(theme)}
                            >
                              <div
                                className="absolute top-0 left-0 w-1/2 h-full rounded-l-lg"
                                style={{
                                  backgroundColor: theme.colors.primary,
                                }}
                              ></div>
                              <div
                                className="absolute top-0 ltr:right-0 rtl:left-0 w-1/2 h-full rounded-r-lg"
                                style={{
                                  backgroundColor: theme.colors.secondary,
                                }}
                              ></div>
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </section>
                  <section>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Line separator color:</p>
                    <div className="flex flex-wrap">
                      {theme.lines.map((line, index) => {
                        const isSelected = selectedLine && selectedLine.color === line.color;
                        return (
                          <div
                            key={index}
                            className={`w-1/3 p-1 jmco-flex-center-v hover:border ${isSelected ? 'border border-white' : 'border-white'} hover:border-white rounded-lg focus:outline-none`}
                          >
                            <button
                              title={line.desc}
                              className="w-full p-3 rounded-lg relative"
                              onClick={() => changeLineTheme(line)}
                            >
                              <div
                                className="absolute top-0 left-0 w-full h-full rounded-lg"
                                style={{ backgroundColor: line.color }}
                              ></div>
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </section>
                  <section>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Scrollbar color:</p>
                    <div className="flex flex-wrap">
                      {theme.scrollbars.map((scrollbar, index) => {
                        const isSelected = selectedScrollbar && selectedScrollbar.color === scrollbar.color;
                        return (
                          <div
                            key={index}
                            className={`w-1/3 p-1 jmco-flex-center-v hover:border ${isSelected ? 'border border-white' : 'border-white'} hover:border-white rounded-lg focus:outline-none`}
                          >
                            <button
                              title={scrollbar.desc}
                              className="w-full p-3 rounded-lg relative"
                              onClick={() => changeScrollbarTheme(scrollbar)}
                            >
                              <div
                                className="absolute top-0 left-0 w-full h-full rounded-lg"
                                style={{ backgroundColor: scrollbar.color }}
                              ></div>
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </section>
                  <section>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Choose your favorite font:</p>
                    <div className="flex flex-wrap">
                      {theme.fonts.map((font, index) => {
                        const isSelected = selectedFont && selectedFont.desc === font.desc;
                        return (
                          <div key={index} className="w-1/3 p-1 jmco-flex-center-v">
                            <button
                              className={`w-full text-sm relative border px-1 py-2 ${isSelected ? 'border-white' : 'border-white'} hover:border-white rounded-lg focus:outline-none`}
                              onClick={() => changeFont(font)}
                            >
                              <span className="dark:text-gray-300">{font.desc}</span>
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </section>
                  <section>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Round corners:</p>
                    <Slider value={roundAll} onChangeValue={changeRoundAll} />
                  </section>
                  <section>
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={shadowAll}
                        onChange={changeShadowAll}
                        className="form-checkbox h-5 w-5 text-blue-600"
                      />
                      <span>Enable 3D effect to all</span>
                    </label>
                  </section>
                  <section>
                    <div className="w-full max-w-md mx-auto bg-white rounded-2xl">
                      <div
                        className="text-sm text-gray-500 p-5 dark:text-gray-400 cursor-pointer flex"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        <i className="fa-solid fa-pager flex items-center justify-center ltr:mr-2 rtl:ml-2"></i>
                        Other Pages
                      </div>
                      <div
                        className={`transition-all duration-300 ${isOpen ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}
                      >
                        <ul className="mt-2">
                          <li
                            className="p-2 border-b cursor-pointer hover:bg-[rgb(var(--primary-color-100))] rounded-lg"
                            onClick={() => navigate('/error')}
                          >
                            <i className="fa-solid fa-bomb"></i> Error 500
                          </li>
                          <li
                            className="p-2 border-b cursor-pointer hover:bg-[rgb(var(--primary-color-100))] rounded-lg"
                            onClick={() => navigate('/404')}
                          >
                            <i className="fa-solid fa-bomb"></i> Error 404
                          </li>
                          <li
                            className="p-2 border-b cursor-pointer hover:bg-[rgb(var(--primary-color-100))] rounded-lg"
                            onClick={() => {
                              throw new Error();
                            }}
                          >
                            <i className="fa-solid fa-bomb"></i> Something went wrong
                          </li>
                        </ul>
                      </div>
                    </div>
                  </section>
                  <section>
                    <button
                      className="text-white justify-center flex items-center bg-blue-700 hover:bg-white w-full focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-white focus:outline-none dark:focus:ring-blue-800"
                      onClick={reset}
                    >
                      Reset
                    </button>
                  </section>
                </div>
              </div>
              <Curve />
            </motion.div>
            <div className="fixed inset-0 bg-gray-900 opacity-10 z-50" onClick={closeDrawer}></div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default DesignOptions;
