export const theme = {
  scrollbars: [
    { color: 'rgb(220 38 38)', desc: 'Red' },
    { color: 'rgb(250, 204, 21)', desc: 'Yellow' },
    { color: 'rgb(219, 39, 119)', desc: 'Pink' },
    { color: 'rgb(192 38 211)', desc: 'Fuchsia' },
    { color: 'rgb(37, 99, 235)', desc: 'Roboto' },
    { color: 'rgb(234, 88, 12)', desc: 'Open Sans' },
    { color: 'rgb(156, 163, 175)', desc: 'Gray' },
    { color: 'white', desc: 'White' },
    { color: 'black', desc: 'Black' },
  ],
  lines: [
    { color: 'rgb(220 38 38)', desc: 'Red' },
    { color: 'rgb(250, 204, 21)', desc: 'Yellow' },
    { color: 'rgb(219, 39, 119)', desc: 'Pink' },
    { color: 'rgb(192 38 211)', desc: 'Fuchsia' },
    { color: 'rgb(37, 99, 235)', desc: 'Roboto' },
    { color: 'rgb(234, 88, 12)', desc: 'Open Sans' },
    { color: 'rgb(156, 163, 175)', desc: 'Gray' },
    { color: 'white', desc: 'White' },
    { color: 'black', desc: 'Black' },
  ],
  fonts: [
    { desc: 'Poppins' },
    { path: 'css/theme/font/font-1.css', desc: 'Montserrat' },
    { path: 'css/theme/font/font-2.css', desc: 'Roboto' },
    { path: 'css/theme/font/font-3.css', desc: 'Open Sans' },
  ],
  colors: [
    {
      path: 'css/theme/color/color-1.css',
      colors: { primary: 'rgb(77, 198, 197)', secondary: 'rgb(255, 117, 104)' },
      desc: 'Teal & Light Coral',
    },
    {
      path: 'css/theme/color/color-2.css',
      colors: { primary: 'rgb(115, 133, 148)', secondary: 'rgb(251, 122, 0)' },
      desc: 'Slate gray & burnt Orange',
    },
    {
      path: 'css/theme/color/color-3.css',
      colors: { primary: 'rgb(41, 121, 255)', secondary: 'rgb(143, 255, 96)' },
      desc: 'Royal blue & olive green',
    },
    {
      path: 'css/theme/color/color-4.css',
      colors: { primary: 'rgb(249, 70, 70)', secondary: 'rgb(189, 189, 189)' },
      desc: 'Burgundy & charcoal gray',
    },
    {
      path: 'css/theme/color/color-5.css',
      colors: { primary: 'rgb(255, 124, 141)', secondary: 'rgb(92, 251, 122)' },
      desc: 'Blush pink & mint green',
    },
    {
      path: 'css/theme/color/color-6.css',
      colors: { primary: 'rgb(255, 99, 114)', secondary: 'rgb(57, 172, 163)' },
      desc: 'Coral pink & turquoise blue',
    },
    {
      path: 'css/theme/color/color-7.css',
      colors: { primary: 'rgb(255, 142, 79)', secondary: 'rgb(92, 251, 122)' },
      desc: 'Peach & mint',
    },
    {
      path: 'css/theme/color/color-8.css',
      colors: { primary: 'rgb(255, 152, 106)', secondary: 'rgb(87, 133, 181)' },
      desc: 'Rose gold & dusty blue',
    },
    {
      path: 'css/theme/color/color-9.css',
      colors: { primary: 'rgb(129, 96, 255)', secondary: 'rgb(255, 204, 21)' },
      desc: 'Lilac purple & soft yellow',
    },
  ],
};
