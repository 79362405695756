import React, { createContext, useContext, useState } from 'react';

const ScrollControlContext = createContext();

export const ScrollControlProvider = ({ children }) => {
  const [scrollEnabled, setScrollEnabled] = useState(true);

  const disableScroll = () => {
    setScrollEnabled(false);
  };
  const enableScroll = () => {
    setScrollEnabled(true);
  };

  return (
    <ScrollControlContext.Provider value={{ scrollEnabled, disableScroll, enableScroll }}>
      {children}
    </ScrollControlContext.Provider>
  );
};

export const useScrollControl = () => {
  return useContext(ScrollControlContext);
};
