import React from 'react';
import Tooltip from 'components/core/Tooltip';
import PropTypes from 'prop-types';

import 'assets/css/components/section/social-link.css';

const SocialLink = ({ label, iconClass, colorClass, href = '#', enableTooltip = true }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={`group jmco-baa8df59-bbf9ad0a ${!enableTooltip && 'jmco-baa8df59-bbf9ad0a-slide'} ${colorClass}`}
    >
      <i className={`${iconClass}`}></i>
      {enableTooltip && <Tooltip label={label} />}
    </a>
  );
};

SocialLink.propTypes = {
  label: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  colorClass: PropTypes.string.isRequired,
  href: PropTypes.string,
  enableTooltip: PropTypes.bool,
};

export default React.memo(SocialLink);
