import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import AppRoute from 'route';
import { DesignOptionsProvider } from 'providers/DesignOptionsProvider';
import { ThemeProvider } from 'providers/ThemeProvider';
import { ScrollControlProvider } from 'providers/ScrollControlProvider';
import { I18nextProvider } from 'react-i18next';

import 'assets/css/main.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import i18n from 'i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <DesignOptionsProvider>
        <ScrollControlProvider>
          <ThemeProvider>
            <BrowserRouter>
              <AppRoute />
            </BrowserRouter>
          </ThemeProvider>
        </ScrollControlProvider>
      </DesignOptionsProvider>
    </I18nextProvider>
  </React.StrictMode>
);
