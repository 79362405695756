import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import ErrorBoundary from 'components/core/ErrorBoundary';
import ScrollToTop from 'components/core/ScrollToTop';
import Loading from 'components/core/Loading';
import Scrollbar from 'components/core/Scrollbar';

// Lazy load components
const About = React.lazy(() => import('./pages/about'));
const BlogDetail = React.lazy(() => import('./pages/blog/detail'));
const Blog = React.lazy(() => import('./pages/blog/index'));
const Contact = React.lazy(() => import('./pages/contact'));
const NotFound = React.lazy(() => import('./pages/error/404'));
const Error = React.lazy(() => import('./pages/error/500'));
const Resume = React.lazy(() => import('./pages/resume'));
const Cart = React.lazy(() => import('./pages/shop/cart'));
const Checkout = React.lazy(() => import('./pages/shop/checkout'));
const Products = React.lazy(() => import('./pages/shop/index'));
const Product = React.lazy(() => import('./pages/shop/product'));
const PlaceOrderSuccess = React.lazy(() => import('./pages/shop/success'));
const Works = React.lazy(() => import('./pages/works'));

const AppRoute = () => (
  <ErrorBoundary>
    <Scrollbar />
    <ScrollToTop />
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<About />} />
        <Route path="/about" element={<About />} />
        <Route path="/resume" element={<Resume />} />
        <Route path="/works" element={<Works />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:slug" element={<Product />} />
        <Route path="/cart/checkout" element={<Checkout />} />
        <Route path="/cart/checkout/success" element={<PlaceOrderSuccess />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<BlogDetail />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="/error" element={<Error />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  </ErrorBoundary>
);

export default AppRoute;
