import { useEffect } from 'react';

import 'assets/css/components/core/scrollbar.css';

const Scrollbar = () => {
  useEffect(() => {
    document.body.classList.add('jmco-custom-scrollbar');
    return () => {
      document.body.classList.remove('jmco-custom-scrollbar');
    };
  }, []);

  return null;
};

export default Scrollbar;
