import * as React from 'react';

const SvgFemale = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width={50}
    height={50}
    viewBox="-27 24 100 100"
    {...props}
  >
    <circle cx={23} cy={74} r={50} fill="#F5EEE5" />
    <defs>
      <circle id="a" cx={23} cy={74} r={50} />
    </defs>
    <clipPath id="b">
      <use xlinkHref="#a" overflow="visible" />
    </clipPath>
    <path fill="#D98C21" d="M-3 65h31v43H-3z" clipPath="url(#b)" />
    <path fill="#CC751F" d="M18 61h31v47H18z" clipPath="url(#b)" />
    <path
      fill="#E6C19C"
      d="M36 95.9c0 4 4.7 5.2 7.1 5.8 7.6 2 22.8 5.9 22.8 5.9 3.2 1.1 5.7 3.5 7.1 6.6v9.8H-27v-9.8c1.3-3.1 3.9-5.5 7.1-6.6 0 0 15.2-3.9 22.8-5.9 2.4-.6 7.1-1.8 7.1-5.8V85h26v10.9z"
      clipPath="url(#b)"
    />
    <g clipPath="url(#b)">
      <defs>
        <path
          id="c"
          d="M36 95.9c0 4 4.7 5.2 7.1 5.8 7.6 2 22.8 5.9 22.8 5.9 3.2 1.1 5.7 3.5 7.1 6.6v9.8H-27v-9.8c1.3-3.1 3.9-5.5 7.1-6.6 0 0 15.2-3.9 22.8-5.9 2.4-.6 7.1-1.8 7.1-5.8V85h26v10.9z"
        />
      </defs>
      <clipPath id="d">
        <use xlinkHref="#c" overflow="visible" />
      </clipPath>
      <path
        fill="#D4B08C"
        d="M23.2 35h.2c3.3 0 8.2.2 11.4 2 3.3 1.9 7.3 5.6 8.5 12.1 2.4 13.7-2.1 35.4-6.3 42.4-4 6.7-9.8 9.2-13.5 9.4h-.6c-3.7-.2-9.5-2.7-13.5-9.4-4.2-7-8.7-28.7-6.3-42.4 1.2-6.5 5.2-10.2 8.5-12.1 3.2-1.8 8.1-2 11.4-2h.2z"
        clipPath="url(#d)"
      />
      <path
        fill="#6B363E"
        d="M-27 82H73v42H-27V82zm50 31c12.1 0 20.8-14.1 20.8-14.1S33.9 83 23 83 2.2 98.9 2.2 98.9 10.9 113 23 113z"
        clipPath="url(#d)"
      />
      <g clipPath="url(#d)">
        <defs>
          <path
            id="e"
            d="M-27 82H73v42H-27V82zm50 31c12.1 0 20.8-14.1 20.8-14.1S33.9 83 23 83 2.2 98.9 2.2 98.9 10.9 113 23 113z"
          />
        </defs>
        <clipPath id="f">
          <use xlinkHref="#e" overflow="visible" />
        </clipPath>
        <path
          fill="#FFF"
          d="M45.4 101.7c-1.3 10-9.3 17.3-9.3 17.3s-8.2-7-10.2-7.6c12.7-7.6 17.1-12 17.1-12s3.6-7.7 2.4 2.3zm-44.7 0C2 111.8 10 119 10 119s8.2-7 10.2-7.6c-12.7-7.6-17.1-12-17.1-12s-3.6-7.7-2.4 2.3z"
          clipPath="url(#f)"
        />
      </g>
    </g>
    <path
      fill="#F2CEA5"
      d="M23 40c19.1 0 20.7 13.8 20.8 15.1 1.1 11.9-3 28.1-6.8 33.7-4 5.9-9.8 8.1-13.5 8.3h-.3L23 97c-3.8-.2-9.6-2.4-13.6-8.3-3.8-5.6-7.9-21.8-6.8-33.8C2.7 53.7 3.9 40 23 40z"
    />
    <path
      fill="#A3705F"
      d="M18.8 85c-.2 0-.4.4-.3.7.9 2.1 2.3 3.3 4.2 3.4 2 .2 4.1-1.2 5.2-3.4.1-.3 0-.7-.3-.7h-8.8z"
    />
    <circle cx={32} cy={69} r={2} fill="#262626" />
    <circle cx={14} cy={69} r={2} fill="#262626" />
    <path fill="none" stroke="#CC9872" strokeWidth={2} d="M8.5 64.2S9.9 63 13.4 63c2.5 0 4.9.7 6.4 2.6" />
    <path fill="none" stroke="#BB8660" strokeWidth={2} d="M37.4 64.2S36 63 32.5 63s-6.9 1.5-7.7 5.7.3 7.7 2.1 10.1" />
    <path
      fill="#E6A422"
      d="M-3 69.3c2.4-.9 7.2-3.5 12-11.9 6.8-12 17.2-6.5 20.5-2.6.4.4.9.2.9.2s8.6-3.7 12 3.8c2.5 5.5 3.9 7.1 6.5 8.8v-7.9C49 44.2 39.5 44 39.1 44s-.7 0-.9-.6C37.3 40.5 28 30.9 12.4 37S-3 65-3 65v4.3z"
    />
    <defs>
      <path
        id="g"
        d="M-3 69.3c2.4-.9 7.2-3.5 12-11.9 6.8-12 17.2-6.5 20.5-2.6.4.4.9.2.9.2s8.6-3.7 12 3.8c2.5 5.5 3.9 7.1 6.5 8.8v-7.9C49 44.2 39.5 44 39.1 44s-.7 0-.9-.6C37.3 40.5 28 30.9 12.4 37S-3 65-3 65v4.3z"
      />
    </defs>
    <clipPath id="h">
      <use xlinkHref="#g" overflow="visible" />
    </clipPath>
    <path fill="#D98C21" d="M29.8 55.6s3-7.5 9-12.1S50 50.9 50 50.9l.3 19.6-20.5-14.9z" clipPath="url(#h)" />
  </svg>
);

export default SvgFemale;
