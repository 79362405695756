import * as React from 'react';
const SvgSomethingWentWrong = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    {...props}
    style={{
      enableBackground: 'new 0 0 100 100',
    }}
    viewBox="0 0 100 125"
    {...props}
  >
    <path d="M45 49h10v4H45zM55 53h4v4h-4zM41 53h4v4h-4zM53 35h4v8h-4zM43 35h4v8h-4z" />
    <path d="M89 19H11c-1.7 0-3 1.3-3 3v48c0 1.7 1.3 3 3 3h37v4H30c-1.1 0-2 .9-2 2s.9 2 2 2h40c1.1 0 2-.9 2-2s-.9-2-2-2H52v-4h37c1.7 0 3-1.3 3-3V22c0-1.7-1.3-3-3-3zm-1 50H12V23h76v46z" />
  </svg>
);
export default SvgSomethingWentWrong;
