import React from 'react';
import PropTypes from 'prop-types';

import 'assets/css/components/core/divider.css';

function Divider({
  vertical = false,
  className = '',
  sticky = false,
  alreadyCentered = false,
  color = '',
  length = '',
}) {
  const classNames = [
    'jmco-2c94667e-11c84f91',
    vertical ? 'jmco-2c94667e-11c84f91-vertical' : 'jmco-2c94667e-11c84f91-horizontal w-[50%]',
    sticky && 'jmco-2c94667e-11c84f91-sticky',
    color || 'jmco-2c94667e-11c84f91-color',
    length || (vertical ? 'h-150' : ''),
    className,
  ]
    .filter(Boolean)
    .join(' ');

  const DividerElement = <div className={classNames}></div>;

  if (alreadyCentered) {
    return DividerElement;
  }

  return <div className="flex justify-center">{DividerElement}</div>;
}

Divider.propTypes = {
  vertical: PropTypes.bool,
  className: PropTypes.string,
  sticky: PropTypes.bool,
  alreadyCentered: PropTypes.bool,
  color: PropTypes.string,
  length: PropTypes.string,
};

export default React.memo(Divider);
