// Function to import all images from a given context
function importAll(r, folder) {
  let images = {};
  r.keys().forEach(item => {
    const key = `${folder}/${item.replace('./', '').replace(/\.\w+$/, '')}`;
    images[key] = r(item);
  });
  return images;
}

// Importing all images from each folder
const blogs = importAll(require.context('./blogs', false, /\.(png|jpe?g|svg|bak)$/), 'blogs');
const certificates = importAll(require.context('./certificates', false, /\.(png|jpe?g|svg)$/), 'certificates');
const clients = importAll(require.context('./clients', false, /\.(png|jpe?g|svg)$/), 'clients');
const draft = importAll(require.context('./draft', false, /\.(png|jpe?g|svg)$/), 'draft');
const products = importAll(require.context('./products', false, /\.(png|jpe?g|svg)$/), 'products');
const profile = importAll(require.context('./profile', false, /\.(png|jpe?g|svg)$/), 'profile');
const works = importAll(require.context('./works', false, /\.(png|jpe?g|svg)$/), 'works');

// Merging all images into a single object
const allImages = {
  ...blogs,
  ...certificates,
  ...clients,
  ...draft,
  ...products,
  ...profile,
  ...works,
};

// Exporting all images
export default allImages;

// Creating and exporting clientLogos array
export const clientLogos = [
  allImages['clients/client-sample-1'],
  allImages['clients/client-sample-2'],
  allImages['clients/client-sample-3'],
  allImages['clients/client-sample-4'],
  allImages['clients/client-sample-5'],
  allImages['clients/client-sample-6'],
];
