import React from 'react';

import ThemeSelectionQuickView from 'components/section/ThemeSelectionQuickView';
import ShoppingCartQuickView from 'components/section/ShoppingCartQuickView';

export const navigation = {
  quickNav: [
    {
      id: 1,
      to: '#',
      iconClass: 'fa-regular fa-moon',
      popoverContent: <ThemeSelectionQuickView />,
    },
    {
      id: 2,
      to: '/cart',
      iconClass: 'fa-solid fa-cart-shopping',
      popoverContent: <ShoppingCartQuickView />,
    },
    { id: 3, to: '/products', iconClass: 'fa-solid fa-box-open' },
  ],
  pagesNav: [
    {
      id: 4,
      to: '/about',
      iconClass: 'fa-solid fa-house-user',
      text: 'about',
      textClass: '',
    },
    {
      id: 5,
      to: '/resume',
      iconClass: 'fa-solid fa-user-tie',
      text: 'resume',
      textClass: 'animate-fitText',
      textClassAr: true,
    },
    {
      id: 6,
      to: '/works',
      iconClass: 'fa-solid fa-briefcase',
      text: 'works',
      indicator: '8',
      textClass: '',
    },
    {
      id: 7,
      to: '/blog',
      iconClass: 'fa-solid fa-blog',
      text: 'blog',
      indicator: '99+',
      textClass: '',
    },
    {
      id: 8,
      to: '/contact',
      iconClass: 'fa-solid fa-envelope-open-text',
      text: 'contact',
      textClass: '',
    },
  ],
};
