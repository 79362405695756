import * as React from 'react';

const SvgMale = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width={50}
    height={50}
    viewBox="-27 23 101 100"
    {...props}
  >
    <circle cx={23} cy={73} r={50} fill="#F5EEE5" />
    <defs>
      <circle id="a" cx={23} cy={73} r={50} />
    </defs>
    <clipPath id="b">
      <use xlinkHref="#a" overflow="visible" />
    </clipPath>
    <path
      fill="#D4B08C"
      d="m38 98.9 27.9 7.7c3.2 1.1 5.7 3.5 7.1 6.6v9.8H-27v-9.8c1.3-3.1 3.9-5.5 7.1-6.6L8 98.9V84h30v14.9z"
      clipPath="url(#b)"
    />
    <g clipPath="url(#b)">
      <defs>
        <path
          id="c"
          d="m38 98.9 27.9 7.7c3.2 1.1 5.7 3.5 7.1 6.6v9.8H-27v-9.8c1.3-3.1 3.9-5.5 7.1-6.6L8 98.9V84h30v14.9z"
        />
      </defs>
      <clipPath id="d">
        <use xlinkHref="#c" overflow="visible" />
      </clipPath>
      <path fill="#1E1E23" d="M23.2 103H16l-4.8-5H-27v25H73.3V98H35.2l-4.8 5z" clipPath="url(#d)" />
      <path fill="#C1CAD4" d="M23 103h1l14.7-7.7-5.8 28.9-9.9-1.1-9.9 1.1-5.8-28.9L22 103z" clipPath="url(#d)" />
      <path fill="#216DD9" d="M20.5 112h5l4 12h-13z" clipPath="url(#d)" />
      <path fill="#249CF2" d="M12.5 103h21l-8 9h-5z" clipPath="url(#d)" />
      <path fill="#FFF" d="m38.5 93-14.4 10 10.8 11L39 94l-.5-1zm-31 0 14.4 10-10.8 11L7 94l.5-1z" clipPath="url(#d)" />
    </g>
    <path
      fill="#F2CEA5"
      d="M23 97c-1.5 0-3.5-.3-4.8-.9-1.6-.7-7.2-4.6-9.1-6.3-2.5-2.3-3.8-5.1-4.2-10S3 57.5 3 53.5C3 47.8 8.1 39 23 39s20 8.8 20 14.5c0 4-1.5 21.5-1.9 26.4s-1.6 7.7-4.2 10c-1.9 1.7-7.6 5.6-9.1 6.3-1.3.5-3.3.8-4.8.8z"
    />
    <path fill="#A3705F" d="M30 83H16c-.4 0-.6.4-.4.8 1.3 2.3 3.7 3.5 6.7 3.7 3.1.2 6.5-1.3 8.1-3.7.2-.3 0-.8-.4-.8z" />
    <circle cx={32} cy={68} r={2} fill="#262626" />
    <circle cx={14} cy={68} r={2} fill="#262626" />
    <path fill="none" stroke="#CC9872" strokeWidth={2} d="M8 65s1.1-3 6.1-3c3.4 0 5.4 1.5 6.4 3" />
    <path fill="none" stroke="#BB8660" strokeWidth={2} d="M38.1 65S37 62 32 62c-4.8 0-7 3-7 5v9" />
    <path
      fill="#D98C21"
      d="M41.8 71.2s.8-6.3 3.7-7.2c.4-1.8 1.5-7 1.5-9.9s-.3-5.7-1.9-8.1c-1.8-2.6-5.6-4.1-7.6-4.1-2.3 1.4-7.7 4.6-9.4 6.5-.9 1 .4 1.8.4 1.8s1.2-.5 1.7-.6c2.5-.7 8-1.2 9.7 1.3C42 53.9 42 62.7 42 64c0 1.2-.2 7.2-.2 7.2z"
    />
    <path
      fill="#E6A422"
      d="M.5 64c2.9 1 3.7 7.2 3.7 7.2S4 65.2 4 64c0-1.6.2-9.1 3.4-12.7 3.6-4 8.4-5.3 11.1-3.5 1.4.9 6.1 5.5 11.1 1.7 3-2.3 8.5-7.5 8.5-7.5s-2.9-8.9-16.1-7.9c-5.6.5-11.8-.9-11.8-.9s-.1 2.5.9 3.8C2.8 39.4.1 45.4-.7 50c-.2.9-.3 1.8-.3 2.7v1.4C-1 57 .1 62.1.5 64z"
    />
  </svg>
);

export default SvgMale;
